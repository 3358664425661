import '../css/app.css';
import './bootstrap';

import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { createApp, h } from 'vue';
import { ZiggyVue } from '../../vendor/tightenco/ziggy';
//import PrimeVue from 'primevue/config';
import Aura from '@primevue/themes/aura';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';


//import 'primevue/resources/themes/saga-blue/theme.css'; // Import the theme
//import 'primevue/resources/primevue.min.css'; // Import PrimeVue styles
//import 'primeicons/primeicons.css'; // Import PrimeIcons


const appName = import.meta.env.VITE_APP_NAME || 'Laravel';

const options = { //TOAST notifications options placeholder
    // You can set your default options here
    timeout: 5000,
};

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) =>
        resolvePageComponent(
            `./Pages/${name}.vue`,
            import.meta.glob('./Pages/**/*.vue'),
        ),
    setup({ el, App, props, plugin }) {
        return createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(ZiggyVue)
            .use(Toast, options)
            .mount(el);
    },
    progress: {
        color: '#4B5563',
    },
});
